/* You can add global styles to this file, and also import other style files */
html {
  touch-action: manipulation;
}

.user-form {
  .mat-form-field-subscript-wrapper {
    z-index: 1;
  }

  .password-strength {
    &__wrapper {
      margin-top: -1.4rem;
      margin-bottom: 1.4rem;

      @media (max-width: 500px) {
        .mat-card-content {
          font-size: 13px;
        }
      }

      @media (max-width: 420px) {
        .mat-card-content {
          font-size: 11px;
        }
      }
    }
  }
}

.mat-raised-button.button--margin-bottom {
  margin-bottom: 1.34375em;
}

.datatable-row-wrapper button.mat-flat-button {
  margin: 0 5px 5px 0;
}

.ng-wig .nw-editor .nw-editor__res {
  display: inline-block;
  white-space: pre-wrap;
}

.cdk-drag.cdk-drag-preview,
.cdk-drop-list.drop-enabled .cdk-drag {
  .contract-field {
    padding-top: 0.5rem;
  }
}

.contract-field--position-overwritten ppa-contract-field .contract-field {
  background-color: rgba(0, 0, 255, 0.1);
}

.contract-field--overwritten ppa-contract-field .contract-field {
  background-color: rgba(112, 203, 41, 0.1);
}
